import { trollzAbi,trollzAddress } from "../contract";
import {ethers,Contract,BigNumber} from 'ethers'
export async function _paidMint(signer,mintAmount){
    try{

        const trollz = new Contract(trollzAddress,trollzAbi,signer);
        const price = await trollz.price();
        const options = {value:price.mul(mintAmount)}
        const mint = await trollz.publicMint(mintAmount,options);
        await mint.wait();
        return true;
    }
    catch(err){
        console.log(err);
    }


}