import { useState,useEffect } from "react";
import {ethers} from 'ethers';
import {_mintAggregator} from './hooks/index'
import {_getTotalSupply} from './hooks/getTotalSupply'
function Home() {
  const [count, setCount] = useState(0)
  // eslint-disable-next-line
  const [wallet, setWallet] = useState(0)
  const [connected,setConnected] = useState(false);
  const[totalSupply,setTotalSupply] = useState(0);

  async function connectHandler(){
    if(window.ethereum){
      const provider = new ethers.providers.Web3Provider(window.ethereum,"any");
      await provider.send('eth_requestAccounts',[]);
      const accounts = await provider.listAccounts();
      if(accounts.length>0){
        setConnected(true);
      }

    }
  }
  async function runOnStartup(){
    if(window.ethereum){
      const provider = new ethers.providers.Web3Provider(window.ethereum,"any");
      const accounts = await provider.listAccounts();
      const totalSupply = await _getTotalSupply(provider);
      setTotalSupply(totalSupply.toString());
      if(accounts.length>0){
        setConnected(true);
      }
    }
  }
  async function mintHandler(){
    if(!window.ethereum) return;
    const provider = new  ethers.providers.Web3Provider(window.ethereum,"any");
    const signer = provider.getSigner();
    const mint = await _mintAggregator(signer,count);
  }
  useEffect(()=>{
    runOnStartup()
  },[])


  return (
    <>
      <section class="w-auto bg-cover h-screen background bg-no-repeat">
        <div class="relative flex min-h-screen flex-col justify-center overflow-hidden py-6 px-4 sm:py-12">

          <div class="mx-auto md:max-w-4xl rounded-3xl bg-[#6a5b46] p-5 text-center w-fit sm:w-[500px] lg:w-[800px]">

            {/* Header Logo */}
            <h2 class="text-5xl font-bold leading-tight text-white mt-5">
              <img src="/img/logo.PNG" className="h-16 mx-auto" alt="logo"/>
            </h2>

            <p class="mt-5"></p>

            {/* Connect URL button */}
            <a
              onClick={connectHandler}
              href="/"
              class=" justify-center gap-2 rounded-full px-2 py-3 text-lg font-medium text-white"
            >
              <img src="/img/cw.PNG" className="h-10 mx-auto" alt="cw" />
            </a>

            {/* Collection Count */}
            <div>
              <p className="text-2xl text-white font-semibold">
                {totalSupply} / 500
              </p>
            </div>

            {/* Here the Mint Button */}
            <button className="mt-6 mb-2"
               onClick={mintHandler}>
           
              <img src="/img/mint.PNG" className="h-10  mx-auto" alt="mint" />
            </button>

            {/* Button and Count Events */}
            <div class="mt-2 flex items-center justify-center gap-2">

              <button
                class="flex items-center justify-center gap-2 rounded-full px-3 py-3 text-lg font-medium text-white"
                onClick={() => {
                  if (count <= 0) {
                    setCount(0)
                  } else {
                    setCount(count - 1)
                  }
                }}
              >
                <img src="/img/mini.PNG" className="h-10" alt="mini" />
              </button>

              <button
                class="flex items-center justify-center gap-2 rounded-full px-2 py-3 text-lg font-medium text-white"
              >
                {count}
              </button>

              <button
                class="flex items-center justify-center gap-2 rounded-full px-3 py-3 text-lg font-medium text-white"
                onClick={() => setCount(count + 1)}
              >
                <img src="/img/plus.png" className="h-10" alt="plus"/>
              </button>

            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Home;
