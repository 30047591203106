import { trollzAbi,trollzAddress } from "../contract";
import {Contract} from 'ethers'
export async function _freeMint(signer,mintAmount){
    try{
        const trollz = new Contract(trollzAddress,trollzAbi,signer);
        const mint = await trollz.freeMint(mintAmount);
        await mint.wait();
        return true;

    }
    catch(err){
        console.log(err);
        return false;
    }


}