import { _getSaleStatus } from "./getSaleStatus";
import { _freeMint } from "./freeMint";
import { _paidMint } from "./paidMint";


export async function _mintAggregator(signer,mintAmount){
    const saleStatus = await _getSaleStatus(signer);
    if(saleStatus == 1){
        await _freeMint(signer,mintAmount);
    }
    if(saleStatus ==2){
        await _paidMint(signer,mintAmount);
    }

}